const questions = [
    {
        id:"1",
        question:"How much do I need for a down payment?",
        answer:"Esteem spirit temper too say adieus who direct esteem esteems luckily or picture placing drawing."
    },
    {
        id:"2",
        question:"How much do I have to pay an agent to help me buy a house?",
        answer:"Esteem spirit temper too say adieus who direct esteem esteems luckily or picture placing drawing."
    },
    {
        id:"3",
        question:"What the first step of the home buying process?",
        answer:"Esteem spirit temper too say adieus who direct esteem esteems luckily or picture placing drawing."
    },
]
export default questions