const AgentsDetail = [
    {
        id:"1",
        Image:require('../img/a1.jpg'),
        name:"Tomas Hardy",
        info:"Busnines Agent"
    },
    {
        id:"2",
        Image:require('../img/a2.jpg'),
        name:"Jack Sparrow",
        info:"Busnines Agent"
    },
    {
        id:"3",
        Image:require('../img/a3.jpg'),
        name:"Mical Hardy",
        info:"Busnines Agent"
    },
    {
        id:"4",
        Image:require('../img/a4.jpg'),
        name:"Jaff Shalbay",
        info:"Busnines Agent"
    },
]
export default AgentsDetail