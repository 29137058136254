const ServicesDetail = [
    {
        id:"1",
        Image:require('../img/wallet.png'),
        heading:"All Cash Offer",
        info:"A small river named Duden flows by their place and supplies it with the necessary regelialia."
    },
    {
        id:"2",
        Image:require('../img/piggy-bank.png'),
        heading:"No Downpayment",
        info:"A small river named Duden flows by their place and supplies it with the necessary regelialia."
    },
    {
        id:"3",
        Image:require('../img/document.png'),
        heading:"Experts in Your Corner",
        info:"A small river named Duden flows by their place and supplies it with the necessary regelialia."
    },
    {
        id:"4",
        Image:require('../img/unlock.png'),
        heading:"Locked in Pricing",
        info:"A small river named Duden flows by their place and supplies it with the necessary regelialia."
    },
]
export default ServicesDetail