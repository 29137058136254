const WorkDetail = [
    {
        id:"1",
        number:"01",
        heading:"All Cash Offer",
        info:"A small river named Duden flows by their place and supplies it with the necessary regelialia."
    },
    {
        id:"2",
        number:"02",
        heading:"Meet Your Agent",
        info:"A small river named Duden flows by their place and supplies it with the necessary regelialia."
    },
    {
        id:"3",
        number:"03",
        heading:"Close the Deal",
        info:"A small river named Duden flows by their place and supplies it with the necessary regelialia."
    },
    {
        id:"4",
        number:"04",
        heading:"Have Your Property",
        info:"A small river named Duden flows by their place and supplies it with the necessary regelialia."
    },
]
export default WorkDetail